#main-sinistro{
    .conteudo-sinistros{
        width: 100%;
        height: 100%;

        .timeline-sinistro{
            width: 100%;
            margin-bottom: 2%;
            display: flex;
            overflow-x: auto;

            .box-status{
                display: flex;
                flex-direction: column;
                margin-bottom: 2%;
                max-width: 11em;
                min-width: 6em;
                align-items: center;
            
                &:first-of-type{
                    .rounded-div{
                        &::after{
                            content: '';
                            position: absolute;
                            background-color: #009600;
                            width: 50%;
                            height: 10px;
                            right: 0px !important;
                            left: 55%;
                        }
                    }
                }
            
                &:last-of-type{
                    .rounded-div{
                        .circulo{
                            background-color: #007bff !important;
                        }

                        &::after{
                            content: '';
                            position: absolute;
                            background-color: #007bff;
                            width: 50%;
                            height: 10px;
                        }
                    }

                    .rounded-green{
                        .circulo-green{
                            background-color: #009600 !important;
                        }

                        &::after{
                            background-color: #009600;
                        }
                    }

                    .rounded-red{
                        .circulo-red{
                            background-color: #ff0000 !important;
                        }

                        &::after{
                            background-color: #ff0000;
                        }
                    }
                }

                .rounded-div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin: 1em 0;
                    color: #fff;
                    width: 100%;

                    &::after{
                        content: '';
                        position: absolute;
                        background-color: #009600;
                        width: 100%;
                        height: 10px;
                        left: 2px;
                    }

                    .circulo{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 25px;
                        height: 25px;
                        border-radius: 50px;
                        background-color: #009600 !important;;
                        z-index: 5;

                        svg{
                            z-index: 5;
                        }
                    }
                }

                h5{
                    font-size: 0.8em;
                    font-weight: 300;    
                    margin: 0 1em;
                    text-align: center;
                }

                h4{
                    font-weight: 500;
                    font-size: .9em;
                }
            }
        }

        .informacoes-sinistro{
            .campo-info{

                h5{
                    font-size: .9em;
                    font-weight: 500;
                    margin-bottom: .2%;
                }

                p, time{
                    font-size: .9em;
                    color: rgb(0,0,0, .8);
                }
            }

            h3{
                width: 100%;
                margin: 2% 0 1.5%;
                font-size: 1em;
            }
        }

        .abas-sinistro{
            width: 100%;
            margin: 2% 0 1%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            border-radius: 4px;

            .navegacao-abas{
                display: flex;
                width: 100%;
                user-select: none;

                .aba-active{
                    background-color: #d8d8d8 !important;
                }
                
                div{
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1% 0;
                    cursor: pointer;
                    transition: 0.5s;

                    &:hover{
                        background-color: #d8d8d8;
                    }

                    h2{
                        font-size: 1.1em;
                        font-weight: 400;
                    }

                    svg{
                        font-size: 1.5em;
                        margin: 0 1% 0 0;
                        display: none;
                    }
                }
            }

            .conteudo-aba{
                width: 100%;
                border-radius: 4px;
                background-color: #fff;
                padding: 2%;

                .opcoes-aba{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 2%;

                    button{
                        padding: .6% 1.5%;
                        border-radius: 4px;
                        margin: 0 0.3em;
                    }
                }

                .conteudo-historicos{
                    display: flex;
                    flex-direction: column;
                }

                .conteudo-documentos{
                    display: flex;
                    flex-direction: column;
                    
                    .container-search{
                        box-shadow: none !important;
                        border: 1px solid rgba(0,0,0,0.16);
                    }

                    .no-info{
                        margin-top: 1.5em;
                    }

                    .tabela-dados{

                        span{
                            padding: .4em 1em;
                            border-radius: 50px;
                            font-weight: 400;
                            font-size: .85em;

                            &.sit-solicitado{
                                background-color: #ffff00;
                            }
                            
                            &.sit-verificacao{
                                background-color: #ff0000;
                                color: #fff;
                            }
                            
                            &.sit-aguardando-envio{
                                background-color: #ff6400;
                                color: #fff;
                            }
                            
                            &.sit-enviado{
                                background-color: #009600;
                                color: #fff;
                            }
                        }

                        &:not(.tabela-documentos-solicitados){
                            .row-dados{
                                cursor: pointer;
                            }
                        }
                    }

                    // .cabecalho-lista{
                    //     display: flex;
                    //     width: 100%;
                    //     padding: 0 calc(1em + 4px);
                    //     margin-bottom: .5em;
                    //     font-size: .9em;
                    //     font-weight: 500;
                    //     display: none;
                    // }

                    // .box-documentos{
                    //     width: 100%;
                    //     padding: .5em 1em;
                    //     margin-bottom: .3em;
                    //     display: flex;
                    //     border-left: 4px solid #007bff;
                    //     cursor: pointer;

                    //     &:last-of-type{
                    //         margin: 0;
                    //     }

                    //     h5{
                    //         font-size: .9em;
                    //         font-weight: 600;
                    //     }

                    //     p{
                    //         font-size: .9em;
                    //         font-weight: 400;
                    //     }
                    // }
                }

                .conteudo-agenda{
                    display: flex;
                    flex-direction: column;

                    .box-agenda{
                        width: 100%;
                        padding: .5% 1%;
                        margin-bottom: 1.5%;
                        display: flex;
                        border-left: 4px solid #007bff;
                        cursor: pointer;
                        box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
                        border-radius: 0 12px 12px 0;
                        transition: 0.5s;

                        &:hover{
                            box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 30%);
                        }

                        &:last-of-type{
                            margin: 0;
                        }

                        h5{
                            font-size: .9em;
                            font-weight: 600;
                        }

                        p{
                            font-size: .9em;
                            font-weight: 400;
                        }
                    }
                }
                
                .conteudo-beneficiarios{
                    display: flex;
                    flex-direction: column;
                }

                .tabela-dados{
                    margin: 0;
                    padding: 0;
                    box-shadow: none;

                    .column-icon{
                        width: 2.5%;
                        font-size: 1em !important;

                        svg{
                            &.fa-trash-can{
                                &:hover{
                                    color: #c22333;
                                }
                            }

                            &.fa-user-pen, &.fa-pen{
                                &:hover{
                                    color: #298129;
                                }
                            }

                            &.fa-eye, &.fa-file-import{
                                &:hover{
                                    color: #007bff;
                                }
                            }

                            &.fa-arrows-rotate{
                                &:hover{
                                    color: #ff6400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#campos-nova-agenda, #campos-novo-beneficiario, #campos-novo-documento, #campos-alterar-status, #campos-solicitar-documento{
    display: flex;
    flex-wrap: wrap;
}