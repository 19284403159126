#main-empresa{
    .conteudo-empresa{
        width: 100%;
        height: 100%;

        .informacoes-empresa{
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);

            .campo-info{
                margin-bottom: clamp(10px, 2%, 15px);

                h5{
                    font-size: .9em;
                    font-weight: 500;
                    margin-bottom: .2%;
                }

                p, time{
                    font-size: .9em;
                    color: rgb(0,0,0, .8);
                }
            }

            h3{
                width: 100%;
                margin: 2% 0 1.5%;
                font-size: 1em;
            }
        }

        .content-abas{
            width: 100%;
            margin: 2% 0 1%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 4px;

            .seletor-abas{
                display: flex;
                width: 100%;
                height: 3em;
                user-select: none;
                
                li{
                    width: calc(100% / 4);
                    height: calc(100% - 5px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: #fff;
                    margin-right: 0.5em;

                    &:last-of-type{
                        margin: 0;
                    }

                    &.aba-active, &:hover{
                        height: 100%;
                        padding-bottom: 1px;
                        border-bottom: 4px solid #00acc1;
                        color: #00acc1;
                    }

                    h2{
                        font-size: 1em;
                        font-weight: 400;
                    }

                    svg{
                        font-size: 1em;
                        margin: 0 .8em 0 0;
                        // display: none;
                    }
                }
            }

            .conteudo-aba{
                width: 100%;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
                padding: 2%;

                .opcoes-aba{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 2%;

                    button{
                        padding: .6% 1.5%;
                        border-radius: 4px;
                    }
                }

                .conteudo-vidas, .conteudo-sinistros{
                    display: flex;
                    flex-direction: column;

                    .no-info{
                        padding: 1em 0;
                    }
                }

                .tabela-dados{
                    margin: 0;
                    padding: 0;
                    box-shadow: none;

                    .row-dados{
                        cursor: pointer;

                        .column-icon{
                            width: 2.5%;
                            font-size: 1em !important;

                            svg{
                                &.fa-user-xmark{
                                    &:hover{
                                        color: #c22333;
                                    }
                                }

                                &.fa-pen{
                                    &:hover{
                                        // color: #298129;
                                        color: #007bff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#campos-form{
    display: flex;
    flex-wrap: wrap;
}