.sit-cadastrado{
    color: #007bff !important;
}

.sit-juntdoc{
    color: #ff6400 !important;
}

.sit-finalizado{
    color: #009600 !important;
}

.sit-aguardanalise{
    color: #005c5c !important;
}

.sit-pagrecu{
    color: #ff0000 !important;
}

.sit-juntdoccomp{
    color: #ffff00 !important;
}

.sit-agupag{
    color: #ff00ff !important;
}

#main-sinistros{
    .conteudo-sinistros{
        width: 100%;
        height: 100%;

        .filtro-conteudo{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2% 1%;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            background-color: #fff;


            #src-geral-sinistro{
                width: 77%;
                padding: .8% 1%;
            }

            .filtro-avancado{
                background-color: rgb(126, 126, 126);
                color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }

            .filtro-buscar{
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }
        }

        .corpo-dados{
            .no-info{
                padding: 2em 0 1em;
            }

            .row-dados{
                cursor: pointer;
            }
        }
    }
}

#campos-novo-sinistro{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;
    }

    h4{
        width: 100%;
        margin: 1% 0 2%;
        padding: 0 0 0 1.5%;
    }
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}