#main-faq{
    align-content: flex-start;

    .content-faq{
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        h3{
            font-weight: 400;
            letter-spacing: .5px;
            margin: 1em 0;

            &:first-of-type{
                margin-top: 0;
            }
        }
    
        .list-itens-faq{
            width: 100%;
            display: flex;
            flex-direction: column;
    
            .content-item-faq{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 2em;
                background-color: #fff;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                margin-bottom: 1em;

                &:last-of-type{
                    margin: 0;
                }

                .titulo-content-item-faq{
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding: 1em 0;

                    h4{
                        font-size: 1em;
                        font-weight: 500;
                    }

                    svg{
                        margin: 0 .4em;
                    }
                }

                .content-answer{
                    width: 100%;
                    max-height: 0;
                    font-size: .9em;
                    opacity: .8;
                    cursor: pointer;
                    display: flex;
                    overflow: hidden;
                    visibility: hidden;
                    transition: all .5s cubic-bezier(.25,.8,.25,1);

                    p{
                        margin-bottom: 1em;
                    }
                }

                .content-answer.show-content-answer{
                    height: auto;
                    max-height: 100px;
                    display: flex;
                    visibility: visible;
                    overflow: hidden;
                    transition: all 3s cubic-bezier(.25,.8,.25,1);
                }
            }
        }
    }
}

#campos-novo-faq{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;
    }
}