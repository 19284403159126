// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

#main-empresas{
    .conteudo-sinistros{
        width: 100%;
        height: 100%;

        .filtro-conteudo{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2% 1%;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            background-color: #fff;


            #src-geral-sinistro{
                width: 77%;
                padding: .8% 1%;
            }

            .filtro-avancado{
                background-color: rgb(126, 126, 126);
                color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }

            .filtro-buscar{
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }
        }
    }
}

// #main-historicos{
//     height: auto;

    .lista-historicos{
        border-radius: 4px;
        // padding: 2% 1%;
        background-color: #fff;
        padding: 2%;
        margin-top: 2%;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    }
// }

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}