#main-testes{
    .conteudo-testes{
        width: 100%;
        height: 100%;

        form{
            width: 50%;
        }

        .campos-form-visita{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2.5%;
        }

        .filtro-conteudo{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2% 1%;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            background-color: #fff;


            #src-geral-sinistro{
                width: 77%;
                padding: .8% 1%;
            }

            .filtro-avancado{
                background-color: rgb(126, 126, 126);
                color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }

            .filtro-buscar{
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }
        }

        .lista-dados{
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            margin: 2% 0 0%;
            padding: 2% 1%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: column;

            
        }
    }

    .row-dados{
        cursor: pointer;
    }
}