.modal{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 99;

    .fundo-modal{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgb(0 0 0 / 40%);
    }

    .content-modal{
        background-color: #fff;
        z-index: 50;
        border-radius: 7px;
        box-shadow: 0px 0px 10px 1px #00000017;
        border: 1px solid rgb(0,0,0, 20%);
        padding: 1%;

        .cabecalho-modal{
            margin-bottom: 4%;
            display: flex;
            width: 100%;
            font-size: 1.1em;
            justify-content: space-between;
            align-items: center;

            h3{
                font-size: 1em;
            }

            svg{
                font-size: 1.2em;
                cursor: pointer;
            }
        }

        .info-modal{
            display: flex;
            flex-wrap: wrap;
        
            .campo-info{
                padding: 0 1.5%;
                margin-bottom: clamp(8px, 1.5%, 11px);
            }

            h3{
                font-size: 1em;
                margin: 2% 1.5%;
            }
        
            h4{
                font-weight: 400;
                font-size: 0.9em;

                span{
                    font-weight: 600;

                    &.span-light{
                        font-size: 0.9em;
                        color: rgb(0 0 0 / 80%);
                        font-weight: 300;
                    }
                }
            }
        
            h5{
                font-weight: 500;
                font-size: 0.9em;
            }
        
            .buttons-info{
                margin-top: 2%;
            }
        }
        
        .buttons-info{
            margin-top: 1em;
            display: flex;
            justify-content: flex-end;

            button{
                padding: 0.45em 1.6em;
                margin: 0 .5%;
                white-space: nowrap;
                cursor: pointer;
                border-radius: 4px;
            }
        }
    }
}