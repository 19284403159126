#main-corretora{
    .areas-corretora{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 30px;

        a{
            width: 100%;

            &:nth-child(1){
                .list-itens{
                    background-color: #B22222e6;
                    color: #fff;
                }
            }

            &:nth-child(2){
                .list-itens{
                    background-color: #28a745e6;
                    color: #fff;
                }
            }

            &:nth-child(3){
                .list-itens{
                    background-color: #17a2b8e6;
                    color: #fff;
                }
            }

            .list-itens{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 15px;
                padding: 6% 3%;
                cursor: pointer;
                transition: 0.5s;
                user-select: none; 

                &:hover{
                    transform: scale(1.02);
                    box-shadow: 0px 3px 6px rgb(0 0 0 / 32%);
                }
        
                svg{
                    margin-bottom: 0.7em;
                    font-size: 2em;
                }

                h3{
                    margin-bottom: .7%;
                    font-weight: 400;
                    font-size: 1.25em;
                }
            }
        }
    }

    .estatisticas-corretora{
        margin-top: 4%;
        width: 100%;

        h3{
            width: 100%;
            font-size: 1.5em;
            font-weight: 400;
            margin-bottom: 3.5%;
        }
        
        .area-estatisticas{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            .box-estatistica{
                width: 100%;
                border-radius: 5px;
                height: 7em;
                background-color: #fff;
                padding: 2% 10%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                box-shadow: 4px 4px 2px rgb(0 0 0 / 16%);
                transition: .5s;
                user-select: none;

                &:hover{
                    box-shadow: 4px 4px 2px rgb(0 0 0 / 32%);
                }

                h4{
                    font-size: 2.2em;
                    font-weight: 500;
                }

                p{
                    font-weight: 400;
                    opacity: .7;
                }
            }
        }
    }
}

#campos-novo-sinistro {
    .radio-box{
        input[type=radio]:last-of-type{
            margin-left: 10%;
        }
    }
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}