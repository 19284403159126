// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

#main-apolices{
    .conteudo-sinistros{
        width: 100%;
        height: 100%;

        .filtro-conteudo{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2% 1%;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            background-color: #fff;


            #src-geral-sinistro{
                width: 77%;
                padding: .8% 1%;
            }

            .filtro-avancado{
                background-color: rgb(126, 126, 126);
                color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }

            .filtro-buscar{
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }
        }
    }
    
}

#campos-nova-apolice{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        height: 100%;

        label{
            span{
                margin-left: 2%;
                font-size: .7em;
            }
        }
    }
}

@media print{
    @page {size: landscape}
}