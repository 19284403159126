.item-ativo{
    background-color: #57669b;
    color: #fff !important;
}

header{
    background-color: #284270;
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    
    // .item-inicio{
    //     width: 100%;
    //     padding: 4.5% 16%;
    //     color: #e4e4e4;
    //     cursor: pointer;
    //     background-color: gray;

    //     &:hover{
    //         background-color: rgba(10, 10, 10, 0.6);
    //         color: #fff !important;
    //     }

    //     a{
    //         width: 100%;
    //         display: flex;
    //         justify-content: center;
    //         cursor: pointer;

    //         svg{
    //             margin-right: 5%;
    //             width: 15%;
    //         }
    //     }
    // }

    .logo-empresa-menu{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.5em;

        img{
            width: 6em;
            object-fit: cover;
            cursor: pointer;
        }
    }
    
    nav{
        width: 100%;
        margin-bottom: 10%;
        display: flex;
        align-items: center;
        flex-direction: column;

        hr{
            width: 90%;
            opacity: 0.4;
            margin: 10% 0;
        }
    }
    
    .itens-lista-menu{
        width: 100%;
        display: flex;
        flex-direction: column;

        .menu-item{
            display: flex;
            align-items: center;
            width: 90%;
            border-radius: 7px;
            margin: 0 auto;
            color: #c5c5c5;
            cursor: pointer;
    
            &:hover{
                background-color: rgb(10,10,10, 0.6);
                color: #fff;
            }

            a{
                display: flex;
                width: 100%;
                padding: 4.5% 3%;
                margin: 0 auto;
                cursor: pointer;
                align-items: center;
            }
    
            svg{
                margin-right: 3%;
                width: 15%;
            }
        }
    }

    .logout{
        padding: 4% 18%;
        width: 100%;
        color: #fff;
        text-align: center;
        cursor: pointer;
        background-color: rgb(235, 80, 80);

        &:hover{
            background-color: rgb(212, 47, 47);
        }
        
        svg{
            margin-right: 5%;
            width: 15%;
        }
    }
}

@media print{
    header{
        display: none;
    }
}