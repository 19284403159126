// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.campos-form-visita{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    h4{    
        width: 100%;
        margin: 1% 0 2%;
        padding: 0 0 0 1.5%;
    }
}

.visita-concluida{
    border-right: 3px solid #009600;
}

.span-concluido{
    background-color: #009600;
    padding: .3% 2%;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    margin-right: 2%;
}

.visita-n-concluida{
    border-right: 3px solid #007bff;
}

.visita-cancelado{
    border-right: 3px solid #ff0000;
}

.span-cancelado{
    background-color: #ff0000;
    padding: .3% 2%;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    margin-right: 2%;
}

.visita-reagendar{
    border-right: 3px solid #ffff00;
}

.span-reagendar{
    background-color: #ffff00;
    padding: .3% 2%;
    border-radius: 5px;
    color: #000;
    font-weight: 500;
    margin-right: 2%;
}

#main-visitas{
    .conteudo-visitas{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .opcoes-agenda{
            width: 35%;
            height: 100%;
            position: sticky;
            top: 3em;
            background-color: #fff;
            border-radius: 4px;
            margin: 2% 5% 0% 0;
            padding: 2% 1%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: column;
        }

        .conteudo-agenda{
            width: 60%;
            background-color: #fff;
            border-radius: 4px;
            margin: 2% 0 0%;
            padding: 2% 2%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: column;

            .categoria-ativa{
                width: 100%;
                border-radius: 50px;

                button{
                    width: calc(100% / 5);
                    // border-radius: 50px;
                    padding: 1% 0;
                    margin-bottom: 5%;
                    cursor: pointer;
                    color: #000000c4;

                    &.btn-ativo{
                        background: #007bff;
                        color: #fff;
                        font-weight: 500;
                    }
                }
            }

            .lista-agenda{
                .item-vazio{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #4d5156;
                }

                .item-agenda{
                    margin-bottom: 5%;

                    &:last-of-type{
                        margin-bottom: 0;
                    }

                    h3{
                        color: #4d5156;
                        font-weight: 500;
                        font-size: 1.1em;
                        margin-bottom: 3%;
                    }

                    .row-agenda{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 2%;
                        margin: 2% 0;
                        cursor: pointer;

                        .horario-agenda{
                            width: 13%;

                            h4{
                                font-weight: 500;
                                font-size: 1.1em;
                                color: #007bff;
                            }

                            h5{
                                opacity: 0.8;
                                font-size: .8em;
                                font-weight: 400;
                            }
                        }

                        .informacoes-agenda{
                            width: 83%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;

                            h4{
                                font-size: 0.8em;
                                line-height: 1.5;
                                font-weight: 400;
                                color: rgb(0 0 0 / 70%);
                            }

                            h5{
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .lista-dados{
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            margin: 2% 0 0%;
            padding: 2% 1%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: column;

            .column-razao-social{
                width: 45%;
            }

            .column-cnpj{
                width: 15%;
            }

            .column-telefone{
                width: 15%;
            }

            .column-email{
                width: 25%;
            }

            .cabecalho-dados{
                width: 100%;
                display: flex;
                background-color: #f3f4fb;
                font-weight: 700;

                div{
                    padding: 1% 0.5%;
                    font-size: 0.9em;
                }
            }

            .corpo-dados{
                width: 100%;
                display: flex;
                flex-direction: column;
                font-weight: 400;

                .row-empresas{
                    width: 100%;
                    display: flex;
                    cursor: pointer;

                    div{
                        padding: 1% 0.5%;
                        font-size: 0.8em;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        span{
                            margin-left: 1%;
                            font-weight: 500;
                        }
                    }
                }

                h5{
                    margin: 1% 1% 0 0;
                    text-align: end;
                }
            }
        }
    }

    .row-dados{
        cursor: pointer;
    }
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}