button{
    &:focus-visible{
        outline: none;
    }
}

ul, li{
    margin: 0;
    padding: 0;
    list-style: none;
}
      
p, h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

.background-login{
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(/assets/backgrounds/fundo-login.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .box-login{
        width: 30%;
        background-color: #fff;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 34%);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img{
            width: 10em;
            margin: 2em 0;
        }

        form{
            width: 85%;
            margin: 0% 0 5%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            flex-direction: column;

            p{
                width: 100%;
                font-size: 0.8em;
                text-align: center;
                color: #918787;
                font-weight: 400;
            }

            input{
                &[type=submit]{
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: 500;
                    background-color: rgb(0, 110, 255);
                    color: #fff;
                    transition: 500ms;
                    border: 0;
                    outline: 0;
                    width: 60%;
                    margin-top: 4em;

                    &:hover{
                        background-color: rgb(0, 99, 228);
                    }
                }
            }

            h3{
                font-size: 0.8em;
                font-weight: 400;
                text-decoration: underline;
                margin-bottom: 2%;
                text-align: end;
                cursor: pointer;
            }
        }
    }

    .box-primeiro-acesso{
        width: 45%;
        padding: 2%;
        background-color: #fff;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 34%);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2{
            font-weight: 600;
            letter-spacing: .5px;
            color: #1d5e14;
        }

        h4{
            margin-top: .5%;
            opacity: .8;
            font-weight: 400;
        }

        >p{
            font-size: .9em;
            font-weight: 400;
            margin-top: 3%;
        }

        form{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: 3%;

            .column-100{
                width: 100%;

                p{
                    margin-top: .5%;
                    font-size: .8em;
                    color: #ff0000;
                }
            }

            .aceite-termos{
                width: 100%;
                display: flex;
                align-items: center;
                margin: 2% 0 4% 0;
                user-select: none;

                label{
                    font-size: .9em;
                    margin-left: 1%;

                    span{
                        cursor: pointer;
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .box-codigo-enviado{
        width: 30%;
        padding: 2%;
        background-color: #fff;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 34%);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2{
            font-size: 1.1em;
            text-align: center;
            margin-bottom: 4%;
        }

        form{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            .inputs-codigo{
                width: 70%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;

                input{
                    width: 20%;
                    aspect-ratio: 1;
                    font-size: 1.2em;
                    text-align: center;
                }

                p{
                    color: #ff0000;
                }
            }

            p{
                font-size: .9em;
                opacity: .8;
                text-align: center;
                margin-top: 4%;
            }

            button{
                margin-top: 5%;
            }
        }

        h4{
            margin-top: 4%;
            font-size: .8em;
            text-align: center;
            font-weight: 400;

            span{
                font-weight: 500;
                cursor: pointer;
                color: #007bff;
                letter-spacing: .2px;
            }
        }
    }

    .box-cadastrar-senha{
        width: 40%;
        padding: 2%;
        background-color: #fff;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 34%);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2{
            font-size: 1.1em;
        }

        h4{
            margin: 4% 0 2%;
            font-size: .9em;
            opacity: .8;
            font-weight: 400;
            text-align: justify;
        }

        >p{
            color: #ff0000;
            font-size: .9em;
            opacity: 1;
            margin-bottom: 5%;
        }

        form{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;

            >div{
                width: 100%;
                margin-bottom: 2%;

                label{
                    font-size: .9em;
                    margin-left: 1%;
                }
            }

            p{
                font-size: .9em;
                opacity: .8;
                margin-bottom: 2%;
                width: 100%;
                text-align: left;
            }
        }
    }
}