// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.cabecalho-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.1em;

    button{
        border-radius: 4px;
        // padding: 1.2% 3%;
        height: 1.9em;
        width: 2.9em;
    }
}

#main-home{
    height: 100vh;
    align-content: space-around;

    .saudacoes-user{
        width: 55%;
        height: 40%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5% 0 0;    
        padding: 1% 2%;
        border-radius: 15px;
        background-color: #284270;
        color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        h2{
            margin-bottom: 1%;
        }

        img{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .area-notificacoes{
        width: 40%;
        height: 40%;
        padding: 1% 2%;
        // margin-right: 5%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .lista-notificacoes{
            line-height: 1.8;
            max-height: 80%;
            overflow-y: auto;

            li{
                display: flex;
                align-items: center;
                cursor: pointer;

                svg{
                    margin-right: 2%;
                    color: rgb(255,193,7, 0.9);
                }
            }
        }
    }

    .area-agenda{
        width: 40%;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        height: 52%;
        padding: 1% 2%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .compromissos-geral{
            overflow-y: auto;
            max-height: 80%;
            width: 100%;

            .compromisso-mes{
                margin: 2% 0;

                .mes-compromisso-agenda{
                    font-size: 1.1em;
                    color: rgb(0,0,0, 0.8);
                    margin-bottom: 2%;
                }

                .box-compromisso-agenda{
                    display: flex;
                    align-items: center;
                    margin: 0 0 1.5% 2%;
                    cursor: pointer;

                    .dia-compromisso{
                        font-size: 1em;
                        padding: 0.3% 2% 0.3% 0;
                        margin-right: 2%;
                        border-right: 3px solid black;
                        
                        &:last-of-type{
                            margin-bottom: 2%;
                        }
                    }

                    .content-compromisso{
                        line-height: 1.5;
                        font-size: 0.9em;
                    }
                }
            }
        }
    }

    .anotacoes-user{
        height: 52%;
        width: 55%;
        padding: 1% 2%;
        font-size: 0.9em;
        margin-left: 5%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .lista-anotacoes{
            max-height: 80%;
            overflow-y: auto;
            padding-right: 2%;

            li{
                cursor: pointer;
                margin: 2% 0 3%;
                padding: 3%;
                display: flex;
                background: #f1f1f1;
                flex-wrap: wrap;
                align-items: center;
                border-radius: 5px;

                &:last-of-type{
                    margin-bottom: 0 !important;
                }

                h4{
                    width: 100%;
                }
            }
        }
    }
}

#campos-nova-anotacao{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;

        &:first-of-type{
            margin-bottom: 3%;
        }
    }
}

.paragrafo-modal{
    padding: 0 1.5%;
    margin-bottom: 3%;
}